export const config = {
    RPC_URL: 'https://secret-4.api.trivium.network:26657',
    REST_URL: 'https://secret-4.api.trivium.network:1317',
    EXPLORER_URL: 'https://www.mintscan.io/secret',
    STAKING_URL: 'https://secret.omniflix.co/stake',
    WALLET_URL: 'https://secret.omniflix.co',
    NETWORK_NAME: 'Secret Network',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'secret-4',
    CHAIN_NAME: 'Secret Network',
    COIN_DENOM: 'SCRT',
    COIN_MINIMAL_DENOM: 'uscrt',
    COIN_DECIMALS: 6,
    PREFIX: 'secret',
    COIN_TYPE: 529,
    COSMOSTAION: 'secret',
    COINGECKO_ID: 'secret',
    GAS_PRICE_STEP_LOW: 0.0125,
    GAS_PRICE_STEP_AVERAGE: 0.1,
    GAS_PRICE_STEP_HIGH: 0.25,
    FEATURES: ['secretwasm', 'ibc-transfer', 'ibc-go'],
};
